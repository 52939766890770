
export default {
  name: 'ErrorDialog',
  props: {
    promise: { type: Object, required: true },
    message: { type: [String, Array as () => string[]], default: 'UnknownErrorOccuredMessage' },
    messageParams: { default: null },
  },
  methods: {
    ok() {
      this.promise.resolve();
    },
    cancel() {
      this.promise.reject();
    },
  },
};
