import { TransactionStatus, InvoiceType, InvoiceStatus } from '~types/enums/api';
import { axiosCompatible as ajax, httpService } from '@/services/http';

export const invoiceDetailsDialog = {
  namespaced: true,
  state: {
    invoice: null,
    isLoading: false,
    lockData: null,
    receptionLockData: null,
    receptionList: null
  },
  getters: {
    isLoaded: (state) => state.invoice != null,
    isLoading: (state) => state.isLoading,
    lockData: (state) => state.lockData,
    receptionLockData: (state) => state.receptionLockData,
    receptionList: (state) => state.receptionList || [],
    invoiceDetails: (state) => (state.invoice ? state.invoice.invoiceDetails : {}),
    receptions: (state) => (state.invoice ? state.invoice.receptions : []),
    paymentRequests: (state) => (state.invoice ? state.invoice.paymentRequests : []),
    returnReasons: (state) => (state.invoice ? state.invoice.returnReasons : []),
    recentCustomerInvoices: (state, getters) =>
      state.invoice ? state.invoice.recentCustomerInvoices.filter((x) => x.id != getters.invoiceDetails.id) : [],
    customerFundsBalance: (state) => (state.invoice ? state.invoice.customerFundsBalance : []),
    auditLogs: (state) => (state.invoice ? state.invoice.auditLogs : []),
    branchInfo: (state) => (state.invoice ? state.invoice.branch : {}),
    receiptsSum: (_state, getters) =>
      getters.receptions
        .filter((x) => x.status <= TransactionStatus.Finalized)
        .reduce((sum, i) => (sum += i.amount), 0),
    receiptsRemaining: (state, getters) => {
      if (state.invoice) {
        const total = getters.invoiceDetails.receivableAmount;
        const result = parseFloat(total) - parseFloat(getters.receiptsSum);
        return result >= 0 ? result : 0;
      }
      return 0;
    },
    paymentRequestsSum: (state) => (state.invoice ? state.invoice.paymentRequestsAmountSum : {}),
    paymentRequestsRemaining: (state, getters) => {
      if (state.invoice) {
        const total =
          getters.invoiceDetails.invoiceType == InvoiceType.Sale
            ? getters.invoiceDetails.receivableAmount
            : getters.invoiceDetails.payableAmount;
        return parseFloat(total) - parseFloat(getters.paymentRequestsSum);
      }
      return 0;
    },
    customerSourceTradingUnitFundBalance: (_state, getters) => {
      const balances = getters.customerFundsBalance.filter(
        (x) => parseInt(x.tradingUnitId) == getters.invoiceDetails.sourceTradingUnit.id,
      );
      return balances.length > 0 ? balances[0].amount : 0;
    },
    customerDestinationTradingUnitFundBalance: (_state, getters) => {
      const balances = getters.customerFundsBalance.filter(
        (x) => parseInt(x.tradingUnitId) == getters.invoiceDetails.destinationTradingUnit.id,
      );
      return balances.length > 0 ? balances[0].amount : 0;
    },
    invoiceCompleted: (state) =>
      state.invoice ? state.invoice.invoiceDetails.status >= InvoiceStatus.Completed : false,
  },

  actions: {
    fetch({ commit, getters }, { guid, setLock } = { guid: undefined, setLock: false }) {
      if (!guid) guid = getters.invoiceDetails.guid;
      return httpService.get('Sales/GetInvoiceDetailsDialog', { guid, setLock }).then(({ data, extraData }) => {
        if (!data.sourceTradingUnit) data.sourceTradingUnit = { symbol: '' };
        if (!data.destinationTradingUnit) data.destinationTradingUnit = { symbol: '' };
        commit('setInvoice', data);
        if (extraData.lock) commit('setLockData', extraData.lock);
      });
    },
    setInvoiceStatus({ state }, model) {
      return new Promise((resolve, reject) => {
        model.id = state.invoice.invoiceDetails.id;
        ajax
          .post('/api/v1/Sales/UpdateInvoiceStatus', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    inPersonInvoiceFinalSubmit({ state }, model) {
      return new Promise((resolve, reject) => {
        model.id = state.invoice.invoiceDetails.id;
        ajax
          .post('/api/v1/Sales/UpdateInPersonInvoiceStatus', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getReception({ commit, getters }, {id}) {
      return new Promise((resolve, reject) => {
        httpService
          .get('Sales/GetReception', { id })
          .then(({ data, extraData }) => {
            commit('setReceptions', data);
            if (extraData.lock) commit('setReceptionLockData', extraData.lock);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createReception({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/CreateReception', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    changeStateOfBankDeposit({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Accounting/ChangeStateOfBankDeposit', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updateReceptionStatus({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UpdateReceptionStatus', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    unassignReception({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UnassignReception', { id: model.id })
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createPaymentRequest({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/CreatePaymentRequest', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deleteReception({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .delete('/api/v1/Sales/DeleteReception/' + model.id)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deletePaymentRequest({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .delete('/api/v1/Sales/DeletePaymentRequest/' + model.id)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setUnlockInvoice(_store, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/SetUnlockInvoice', { id: model.id })
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  mutations: {
    setInvoice(state, data) {
      state.invoice = data;
    },
    setReceptions(state, data){
      state.receptionList = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLockData(state, data) {
      state.lockData = data;
    },
    setReceptionLockData(state, data) {
      state.receptionLockData = data;
    }
  },
};
