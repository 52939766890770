import Vue from 'vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import ErrorDialog from '@/components/dialogs/ErrorDialog.vue';
import { i18n } from '@/lang';
import { router } from '@/router';
import { store } from '@/store';
import { bus } from './bus';
// import { sleep } from '@/lib';

const openModals = new Set<any>();

bus.on('after-route-change', () => {
  for (const modal of openModals) {
    modal.promise?.reject();
  }
});

async function open(component: any | string, props: any = {}): Promise<any> {
  if (typeof component === 'string') {
    if (!Vue.component(component)) {
      component += 'Dialog';
      const module = await import(`@/components/dialogs/${component}.vue`);
      Vue.component(component, module.default);
    }
    store.commit('pushDialog', { name: component, props });
    return;
  }

  props.promise = {};
  props.promise.object = new Promise((resolve, reject) => {
    props.promise.resolve = resolve;
    props.promise.reject = reject;
  });

  const ComponentClass = component instanceof Vue ? component : Vue.extend(component);
  const modal = new ComponentClass({ propsData: props, i18n, store, router }).$mount();
  modal.$on('bv::modal::hide', () => {
    modal.$refs.modal.promise?.reject();
  });
  openModals.add(modal);
  modal.$refs.modal.show();
  props.promise.object.finally(() => {
    modal.$destroy();
    openModals.delete(modal);
  });
  // sleep(0).then(
  //   props.promise.object.catch((e) => {
  //     if (e != null) throw e;
  //   }),
  // );
  return props.promise.object;
}

function error(message: string | string[], messageParams: any = null) {
  if (typeof message == 'string') {
    if (i18n.te(message?.replace(/\s+/g, ''))) message = message?.replace(/\s+/g, '');
  } else {
    message = message.map((msg) => {
      if (i18n.te(msg?.replace(/\s+/g, ''))) return msg?.replace(/\s+/g, '');
      return msg;
    });
  }

  return open(ErrorDialog, { message, messageParams });
}

function confirm(message: string, props?: any) {
  return open(ConfirmDialog, { message, ...props });
}

export const dialogService = { confirm, error, open };
