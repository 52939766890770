
export default {
  name: 'ConfirmDialog',
  props: {
    promise: {},
    title: { default: 'Attention' },
    message: { default: 'AreYouSure' },
    messageParams: { default: () => {} },
    yesText: { default: 'Yes' },
    noText: { default: 'No' },
    resolves: { type: Array as () => string[], default: () => [] },
  },
  methods: {
    ok() {
      this.promise.resolve();
    },
    resolve(item) {
      this.promise.resolve(item);
    },
    cancel() {
      this.promise.reject();
    },
  },
};
