import { axiosCompatible as ajax, httpService } from '@/services/http';

export const paymentDialog = {
  namespaced: true,

  state: {
    rootModel: null,
    isLoading: false,
    lockData: null,
  },

  getters: {
    isLoaded: (state) => state.rootModel != null,
    isLoading: (state) => state.isLoading,
    lockData: (state) => state.lockData,
    invoice: (state) => (state.rootModel ? state.rootModel.invoice : null),
    payment: (state) => (state.rootModel ? state.rootModel.payment : null),
    paymentOrder: (state) => (state.rootModel ? state.rootModel.paymentOrder : null),
    extraFees: (state) => (state.rootModel ? state.rootModel.extraFees : null),
    auditLogs: (state) => (state.rootModel ? state.rootModel.auditLogs : null),
    messages: (state) => (state.rootModel ? state.rootModel.messages : null),
    swiftMessages: (state) => (state.rootModel ? state.rootModel.swiftMessages : null),
    payFromOrganizationPhysicalAccount: (state) =>
      state.rootModel ? state.rootModel.payFromOrganizationPhysicalAccount : null,
    depositToCustomerBankAccount: (state) => (state.rootModel ? state.rootModel.depositToCustomerBankAccount : null),
    payToCustomerContact: (state) => (state.rootModel ? state.rootModel.payToCustomerContact : null),
    readOnly: (state) => (state.rootModel ? state.rootModel.readOnly : true),
  },

  actions: {
    load({ commit, state }, { guid, setLock } = { guid: undefined, setLock: false }) {
      if (!guid) guid = state.rootModel.payment.guid;
      if (state.rootModel) commit('setRootModel', null);
      return new Promise((resolve, reject) => {
        httpService
          .get('Sales/GetPaymentDialog', { guid, setLock })
          .then(({ data, extraData }) => {
            commit('setRootModel', data);
            if (extraData.lock) commit('setLockData', extraData.lock);
            resolve(data);
          })
          .catch((e) => reject(e));
      });
    },
    loadExtraFees({ commit, state }) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/GetPaymentExtraFees', { id: state.rootModel.payment.id })
          .then((r) => {
            commit('setExtraFees', r.data);
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    createNote({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/CreatePaymentNote', model)
          .then((r) => {
            dispatch('load');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setUnlockPayment(_store, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/SetUnlockPayment', { id: model.id })
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setPaymentStatus({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UpdatePaymentStatus', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  mutations: {
    setRootModel(state, data) {
      state.rootModel = data;
    },
    setExtraFees(state, data) {
      state.rootModel.extraFees = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLockData(state, data) {
      state.lockData = data;
    },
  },
};
