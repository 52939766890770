export enum AccountNature {
  Debt = 0,
  Credit = 1,
}

export enum AccountType {
  General = 0,
  Subsidiary = 1,
  Detailed = 2,
}

export enum BankDepositState {
  Unknown = 0,
  DepositToBusinessPartyWallet = 1,
  DepositToChequeCollection = 2,
  DepositToPos = 3,
  DepositToCumulativePos = 4,
  Rejected = 5,
}

export enum ChequeChangeStateAction {
  SendForProcess = 0,
  Collected = 1,
  BankRejected = 2,
  TransferToOtherCustomer = 3,
  Backward = 4,
  Rejected = 5,
}

export enum ChequeState {
  ChequesInFund = 0,
  InProcess = 1,
  BankRejected = 2,
  Collected = 3,
  TransferedToOthers = 4,
  Rejected = 5,
}

export enum ClientReferralType {
  Branch = 1,
  Agent = 2,
  Customer = 3,
  InPerson = 4,
}

export enum CustomerExchangeState {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum CustomerExchangeUpdateStateAction {
  Proceed = 1,
  Reject = 2,
  Return = 3,
}

export enum DealingType {
  Cash = 1,
  Remittance = 2,
  Exchange = 3,
}

export enum DeferredReceptionStatus {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum DeferredReceptionUpdateStateAction {
  Proceed = 1,
  Reject = 2,
}

export enum IdType {
  PRCard = 0,
  CanadianDriversLicense = 1,
  Passport = 2,
  ProvincialIDCard = 3,
}

export enum EncryptDecryptType {
  Default = 1,
  AttachmentId = 2,
  TicketId = 3,
  TicketCommentId = 4,
  UserPhoneId = 5,
  TagId = 6,
  PaymentRequestTypeId = 7,
}

export enum EntityTypeEnum {
  Ticket = 1,
  Payment = 2,
}

export enum LogsEntityType {
  Agent = 18, //کارگزار
  Contractor = 20, //پیمانکار
  Customer = 21, //مشتری
  BankAccount = 32, //  حساب بانکی
  Employee = 68, //کارمند
  Invoice = 78, //فاکتور
  Payment = 85, //پرداخت
  PhysicalAccountTradingUnitChange = 87, //تبدیل
  Reception = 90, //دریافت
  Fund = 128, //صندوق
}

export enum FieldType {
  Text = 1,
  SingleOption = 2,
}

export enum InvoiceStatus {
  OnGoing = -1,
  Checking = 1,
  Checkout = 2,
  ReceiptApproval = 3,
  FinalApproval = 4,
  WaitingForCompletion = 5,
  Completed = 6,
  Rejected = 7,
}

export enum InvoiceType {
  Purchase = 1,
  Sale = 2,
}

export enum InvoiceUpdateAction {
  Proceed = 0,
  ProceedWithoutCheckout = 1,
  Reject = 2,
  Return = 3,
  DirectCompletion = 4,
  CompleteWithoutPaymentToCustomer = 5,
  proceedWithoutPaymentReceive = 6,
  ProceedWithoutReceipt = 7,
}

export enum CostInvoiceStatus {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum LogType {
  Customers = 1,
  Invoices = 2,
  PaymentOrders = 3,
  PaymentRequest = 4,
  Reception = 5,
}

export enum MongoQueryType {
  Eq = 1,
  Ne = 2,
  AnyEq = 3,
  Gt = 4,
  Gte = 5,
  Lt = 6,
  Lte = 7,
  In = 8,
  Nin = 9,
  Or = 10,
  And = 11,
}

export enum BranchCostPaymentMethod {
  InPersonCounter = 1,
  DepositToAccount = 2,
  DepositToOrganizationFund = 3,
  Online = 4,
  FastInPerson = 5
}

export enum PettyCashPaymentMethod {
  ByCash = 1,
  Bank = 2,
}

export enum PaymentMethod {
  InPerson = 1,
  DepositToAccount = 2,
  DepositToOrganizationFund = 3,
  EmailTransfer = 4
}

export enum PaymentOrderType {
  Normal = 1,
  Barter = 2,
}

export enum CostType {
  AccountingPettyCash = 1,
  AccountingBranchCosts = 2,
}

export enum PaymentRequestMethod {
  InPersonBranch = 1,
  InPersonAgent = 2,
  DepositToAccount = 3,
  DepositToOrganizationFund = 4,
  BankingEmail = 5
}

export enum PaymentRequestProcessingStatus {
  Processing = 1,
  Processed = 2,
}

export enum PaymentRequestStatus {
  Pending = 1,
  Referral = 2,
  InProcess = 3,
  Finalized = 4,
  Rejected = 5,
}

export enum PaymentRequestBranchReferralType {
  Internal = 0,
  Incoming = 1,
  Outgoing = 2,
}

export enum DynamicFields {
  TransitNumber = 'Transit Number',
  Occupation = 'Account Holder Job',
}

export enum PurposeOfTransaction {
  'Family Support' = 1,
  'Buying Property' = 2,
  'Car Purchase' = 3,
  'Living Expenses' = 4,
  'Investment' = 5,
  'Business Expenses' = 6,
  'Mortgage/Debt Payment' = 7,
  'Paying Tuition Fees and Student Expenses' = 8,
  'Rent Payment' = 9,
  'Transfer of Personal Assets' = 10,
  'Personal Bill Payment' = 11,
  'Personal Loan' = 12,
  'Other' = 13,
}

export enum SourceOfFund {
  'Bank Account/Saving' = 1,
  'Income/Business' = 2,
  'Selling Property/Assets' = 3,
  'Inheritance' = 4,
  'Gift' = 5,
  'Borrow/Loan' = 6,
  'Family Support' = 7,
  'Pension' = 8,
  'Other' = 9,
}

export enum PaymentStatus {
  Checking = 1,
  ReadyForBank = 2,
  SentToBank = 3,
  Paid = 4,
  ReturnedFromBank = 5,
  Rejected = 6,
  Returned = 7,
  Current = 8,
}

export enum PaymentTransferType {
  None = 0,
  Cash = 1,
  Deposit = 2,
  BranchDeposit = 3,
  Customer = 4,
}

export enum PaymentUpdateAction {
  Proceed = 1,
  Reject = 2,
  Return = 3,
}

export enum PettyCashStatus {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum PettyCashCostStatus {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum PettyCashUpdateStateAction {
  Proceed = 1,
  Reject = 2,
}

export enum PhoneTypeEnum {
  Mobile = 2,
  Email = 1,
  Telephone = 3,
  Telegram = 5,
  WhatsApp = 6,
  Fax = 4,
}

export enum PhysicalAccountExchangeState {
  Pending = 0,
  Finalized = 1,
  Rejected = 2,
}

export enum PhysicalAccountExchangeUpdateStateAction {
  Proceed = 1,
  Reject = 2,
  Return = 3,
}

export enum PhysicalAccountTag {
  Fca = 1,
  Mca = 2,
  Salary = 4,
  PettyCash = 8,
  Ordinary = 16,
}

export enum PhysicalAccountType {
  BankAccount = 1,
  Fund = 2,
  CustomerAccount = 3,
}

export enum QueryParamSortOrder {
  Asc = 1,
  Desc = 2,
}

export enum ReasonType {
  Create = 1,
  Update = 2,
  Delete = 3,
  Return = 4,
  Reject = 5,
  ReturnToCheckout = 6,
  ReturnToFinalApproval = 7,
  CancelDeal = 8,
  ReturnPayment = 9,
}

export enum ReceiptMethod {
  Cash = 1,
  Interbank = 2,
  CardToCard = 3,
  Online = 4,
  Pos = 5,
  Cheque = 6,
  Barter = 7,
  CryptoCurrency = 8,
  Other = 32767,
}

export enum ReceiptApprovalType {
  Input = 0,
  Output = 1,
}

export enum ReferralType {
  Agent = 1,
  Customer = 2,
  BranchTt = 3,
  BranchLocal = 4,
  InPerson = 5,
  CryptocurrencyBranch = 6,
  BankingEmail = 7
}

export enum RegistrationType {
  OnlineRegistration = 1,
  InPersonRegistration = 2,
}

export enum SystemAccountType {
  Normal = 0,
  Organization = 10,
  OrganizationFund = 11,
  OrganizationBankAccount = 12,
  Customer = 20,
  Income = 30,
  IncomeWage = 31,
  IncomeDiscount = 62,
  TaahohadatePardakhtani = 41,
  TaahohadateTaeedNashodeArzi = 42,
  SoodVaZianTabdilArzi = 43,
  VojoohDarRahePos = 44,
  HazinehaKarmozdBanki = 51,
  TankhahGardan = 61,
  VojooheEnteghaliBeynShoab = 63,
  BankProfit = 64,
  BankCost = 65,
  Contractor_Warranty = 66,
  Infeasible_Costs = 67,
  Tree_DarayeeJari_SayerHesabhaVaAsnadDaryaftani = 104,
  Tree_DarayeeJari_AsnadDaryanftani_Cheque = 105,
  Tree_DarayeeJari_MojudiNaqd_ChequeDarJaryanVosul = 106,
}

export enum TicketDepartmentType {
  Technical = 1,
  Financial = 2,
  Administrator = 3,
}

export enum TicketStatusType {
  InProgress = 1,
  Closed = 2,
  ToDo = 3,
  InFuture = 4,
  Blocked = 5,
  CancelOrResolved = 6,
}

export enum TradingUnitChangeStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum TradingUnitType {
  NormalCurrency = 1,
  CryptoCurrency = 2,
  Coin = 3,
  Stocks = 4,
}

export enum LocalPaymentMethod {
  Funds = 0,
  Branches = 1,
  Internet = 2,
}

export enum LocalPaymentTransactionStatus {
  Waiting = 1,
  Pending = 2,
  Checking = 3,
  Finalized = 4,
  Rejected = 5,
}

export enum LocalPaymentOrderType {
  Fund = 1,
  Bank = 2,
  Online = 3,
  Barter = 4,
}

export enum LocalPaymentOrderStatus {
  Checking = 1,
  Finalized = 2,
  Rejected = 3,
}

export enum LocalPaymentStatus {
  Checking = 1,
  SendToBank = 2,
  Paid = 3,
  Rejected = 4,
}

export enum LocalPaymentProcessingStatus {
  Processing = 1,
  Processed = 2,
}

export enum TransactionStatus {
  Pending = 1,
  Checking = 2,
  Finalized = 3,
  Rejected = 4,
}

export enum UserAttachmentType {
  PassportPicture = 1,
  PromissoryNote = 27,
  NationalCardPicture = 2,
  ProfilePicture = 3,
  DrivingCertificatePicture = 4,
  IranianCertificate = 5,
  IcPicture = 6,
  OfficialNewspaperImage = 7,
  BusinessLicenseImage = 8,
  AssociationArticlesImage = 9,
  CompanyCertificateImage = 10,
  OtherAttachments = 20,
  Resume = 21,
  EducationDocument = 22,
  BirthCertificate = 23,
  EmploymentForm = 24,
  PersonalImage = 25,
}

export enum UserAttachmentState {
  Approved = 1,
  NotApproved = 2,
  Rejected = 3,
}

export enum PaymentOrderState {
  processed = 1,
  unProcessed = 2,
}

export enum UserStateType {
  BeUserType = 100,
  IsActive = 101,
  Confirmed = 102,
  Awaiting = 103,
  Ignored = 104,
  Deleted = 105,
}

export enum UserStateTypeReasons {
  BeUserType = 100,
  IsActive = 101,
  Confirmed = 102,
  Awaiting = 103,
  Ignored = 104,
}

export enum UserType {
  Employee = 1,
  Customer = 2,
  Agent = 4,
  Contractor = 8,
}

export enum ValidationType {
  Email = 1,
  Required = 2,
  Date = 3,
  DateTime = 4,
  Length = 5,
  Format = 6,
}

export enum VoucherType {
  Normal = 0,
  Opening = 1,
  Closing = 2,
  ReturnOfExpenses = 3,
  Loan = 4,
}

export enum VoucherEventTypeName {
  BaridInvoiceStatusChangedEvent = "BaridInvoiceStatusChangedEvent",
  BaridBankDepositStatusChangedEvent = "BaridBankDepositStatusChangedEvent",
  BaridLocalPaymentStatusChangedEvent = "BaridLocalPaymentStatusChangedEvent",
  BaridPaymentRequestCreatedEvent = "BaridPaymentRequestCreatedEvent",
  BaridPaymentRequestStatusChangedEvent = "BaridPaymentRequestStatusChangedEvent",
  BaridPaymentStatusChangedEvent = "BaridPaymentStatusChangedEvent",
  BaridPhysicalAccountCostStatusChangedEvent = "BaridPhysicalAccountCostStatusChangedEvent",
  BaridReceiptStatusChangedEvent = "BaridReceiptStatusChangedEvent",
  BaridWageCreatedEvent = "BaridWageCreatedEvent",
  BaridWageStatusChangedEvent = "BaridWageStatusChangedEvent",
  BaridPettyCashStateUpdatedEvent = "BaridPettyCashStateUpdatedEvent",
  BaridCustomerExchangeStateUpdatedEvent = "BaridCustomerExchangeStateUpdatedEvent",
  BaridPettyCashCostStateUpdatedEvent = "BaridPettyCashCostStateUpdatedEvent",
  BaridPhysicalAccountProfitStatusChangedEvent = "BaridPhysicalAccountProfitStatusChangedEvent",
  BaridPhysicalAccountTradingUnitChangeEvent = "BaridPhysicalAccountTradingUnitChangeEvent",
  // BaridPaymentCreatedEvent = "BaridPaymentCreatedEvent",
  BaridPhysicalAccountExchangeStateUpdatedEvent = "BaridPhysicalAccountExchangeStateUpdatedEvent",
  BaridCostInvoiceStatusChangedEvent = "BaridCostInvoiceStatusChangedEvent"
}

export enum BaridAccountingItemProcessResult {
  // [Display(Name = " خطای اطلاعاتی ")]
  ParameterExeption = 11,
  // [Display(Name = "ارسال نشده")]
  NotSend = 0,
  // [Display(Name ="ثبت موفق")]
  Successful = 1,
  // [Display(Name = "موجودیت یافت نشد")]
  EntityNotFound = 2,
  // [Display(Name = "حالت قبلی یافت نشد ")]
  PreviuosStateProcessIsNotFound = 3,
  // [Display(Name = "فرایند حالت قبلی موفقیت امیز نبود ")]
  PreviousStateProcessIsNotSuccessful = 4,
  // [Display(Name = "اتصال ناموفق به برید ")]
  BaridApiCallException = 5,
  // [Display(Name = "خطای اعتبار سنجی برید ")]
  BaridApiCallValidationErrors = 6,
  // [Display(Name = "وضعیت جاری پشتیبانی نمی شود ")]
  CurrentStateTransitionIsNotSupported = 7,
  // [Display(Name = "  فرایند پیاده سازی نشده است ")]
  EventHanlderNotFound = 8,
  // [Display(Name = " تبدیل یافت نشد ")]
  RateNotFound = 9,
  // [Display(Name = " مرکز هزینه یافت نشد ")]
  BranchCostCenterNotFound = 10,
  // [Display(Name = " رویداد یافت نشد  ")]
  EventTypeNotFound = 12,
  // [Display(Name = " حساب بانکی وجود ندارد ")]
  PhysicalAccountNotFound = 13
}

export enum AccountVoucherType {
  Debtor = 0,
  Creditor = 1,
}

export enum UserFinancialStatus {
  Debtor = 1,
  Creditor = 2,
}

export enum WageType {
  Reception = 1,
  Payment = 2,
  Deleted = 3,
}

export enum AccountWageType {
  Cost = 1,
  Profit = 2,
  Rejected = 3,
}

export enum WageStatus {
  Confirmed = 1,
  Rejected = 2,
}

export enum GroupAccountType {
  Temporary = 1,
  Permanent = 2,
  Security = 3,
}

export enum attachmentFileType {
  image = 1,
  pdf = 2,
}
export enum TradingUnitCategory {
  Physical = 1,
  Virtual = 2,
}
export enum ReceptionStatus {
  Pending = 1,
  Checking = 2,
  Finalized = 3,
  Rejected = 4,
}

export enum CostAssetsStatus {
  Pending = 1,
  Checking = 2,
  Finalized = 3,
  Rejected = 4,
  Total = 5,
}

export enum VoucherFilterType {
  All = 0,
  WithoutReference = 1,
  WithReference = 2,
}
export enum AccountReferenceType {
  PhysicalAccount = 1,
  BusinessParty = 2,
}
export enum PosState {
  NotReceived = 1,
  Received = 2,
  Rejected = 3,
}

export enum UserRegisterationMethod {
  Arvanex = 0,
  CustomerPortal = 1,
}

export enum MessageType {
  Sms = 0,
  Email = 1,
}

export enum VoucherSystemDescription {
  // Accounting >= 100 && < 200

  ChequeSendToBank = 131,
  ChequeBankCollected = 132,
  ChequeBankRejected = 133,
  ChequeTransferToOtherCustomer = 134,
  ChequeBackwardToInProcess = 138,
  ChequeBackward = 139,

  BankDepositCreate = 140,
  BankDepositToPos = 141,
  BankDepositBackward = 149,

  PhysicalAccountProfitCreate = 150,
  PhysicalAccountProfitReject = 151,
  PhysicalAccountCostCreate = 152,
  PhysicalAccountCostReject = 153,

  FinalizeCostInvoice = 154,
  RejectCostInvoice = 155,

  // Sales >= 200 && < 500

  InvoiceCreate = 200,
  InvoiceChargeCustomer = 201,
  InvoiceBackward = 209,

  ReceptionCreate = 210,
  ReceptionBackward = 219,

  PaymentOrderCreate = 220,
  PaymentOrderBackward = 229,

  PaymentCreate = 230,
  PaymentBackward = 239,

  PaymentExtraFeeCreate = 240,
  PaymentExtraFeeBackward = 249,

  WageCreate = 250,
  WageBackward = 259,

  // Misc >= 500

  PhysicalAccountExchangeCreate = 510,
  PhysicalAccountExchangeBackward = 514,

  CustomerExchangeCreate = 515,
  CustomerExchangeBackward = 519,

  PettyCashCreate = 525,
  PettyCashBackward = 526,

  CustomerTradingUnitChangeConfirm = 536,
  PhysicalAccountTradingUnitChangeConfirm = 537,
  CustomerTradingUnitChangeReturn = 538,
  PhysicalAccountTradingUnitChangeReturn = 539,

  PettyCashCostCreate = 546,
  PettyCashCostBackward = 546,

  ManualVoucher = 600,
  ReturnManualVoucher = 601,
}

export enum NotificationReceiverType {
  Sms = 1,
  Email = 2,
  Channel = 3,
}

export enum CostAssetUrgencyModality {
  Normal = 1,
  Urgent = 2,
  VeryUrgent = 3,
}

export enum CostHeadingTypes {
  Opex = 1,
  Capex = 2,
  Payroll = 3,
}

export enum NotificationType {
  Sms = 1,
  Email = 2,
  Mattermost = 3,
}
export enum EventType {
  PaymentRequest = 1,
  CostInvoice = 2,
  CustomerExchange = 3,
  ExchangAccount = 4,
  PayFee = 5,
  ReceptFee = 6,
  Payment = 7,
  PhysicalAccountCost = 8,
  PhysicalAccountProfit = 9,
  PaymentOrder = 10,
  LocalPayment = 11,
  BankDeposit = 12,
  Reception = 13,
  Invoice = 14,
  NewLocalPayment = 15,
  Online = 16,
  Fund = 17,
  Branch = 18,
}
