import { axiosCompatible as Ajax } from '@/services/http';

export const userContacts = {
  namespaced: true,

  state: {
    userContacts: [],
    firstTimeLoaded: false,
  },

  getters: {
    allUserContacts: (state) => state.userContacts,
  },

  actions: {
    fetch({ commit }, model) {
      return Ajax.get('/api/v1/profile/getAllContacts', model).then((r) => {
        commit('setUserContacts', r.data);
      });
    },
    create({ commit }, model) {
      return Ajax.post('/api/v1/profile/createContact', model).then((r) => {
        commit('addUserContact', r.data);
        return r.data;
      });
    },
    update(_store, model) {
      return Ajax.put('/api/profile/updateContact', model).then((r) => {
        return r.data;
      });
    },
    delete(_store, id) {
      return Ajax.delete('/api/v1/profile/deleteContact/' + id).then((r) => {
        return r.data;
      });
    },
    getUserContactByID(_store, id) {
      return new Promise((resolve) => {
        Ajax.get('/api/v1/profile/getContact?id=' + id).then((r) => {
          resolve(r.data);
        });
      });
    },
  },

  mutations: {
    setFirstTimeLoaded(state) {
      state.firstTimeLoaded = true;
    },
    setUserContacts(state, data) {
      state.userContacts = data;
    },
    addUserContact(state, data) {
      state.userContacts.push(data);
    },
  },
};
