import { bus } from '@/services/bus';

function loader<T>(this: Promise<T>, name: string): Promise<T> {
  bus.emit('loading', [name, true]);
  return this.then((val) => {
    bus.emit('loading', [name, false]);
    return val;
  }).catch((e) => {
    bus.emit('loading', [name, false, e]);
    return Promise.reject(e);
  });
}

function cmp(a: any, b: any, desc: number) {
  return desc * (a < b ? -1 : a > b ? 1 : 0);
}

function sortBy<T>(this: T[], key: keyof T | ((x: T) => any), desc = false) {
  const sign = desc ? -1 : 1;
  const sortFn =
    typeof key === 'function'
      ? (a: any, b: any) => cmp(key(a), key(b), sign)
      : (a: any, b: any) => cmp(a[key], b[key], sign);
  return this.sort(sortFn);
}

Object.defineProperty(Promise.prototype, 'loader', { writable: false, enumerable: false, value: loader });
Object.defineProperty(Array.prototype, 'sortBy', { writable: false, enumerable: false, value: sortBy });

declare global {
  interface Promise<T> {
    loader(name: string): Promise<T>;
  }

  interface Array<T> {
    sortBy(this: T[], key: keyof T | ((x: T) => any), desc?: boolean): T[];
  }
}
