import { render, staticRenderFns } from "./ErrorDialog.vue?vue&type=template&id=3a2b4440&"
import script from "./ErrorDialog.vue?vue&type=script&lang=ts&"
export * from "./ErrorDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../assets/js/vuetify-loader/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BModal } from 'bootstrap-vue'
installComponents(component, {BButton,BModal})
