import { Component, Vue } from 'vue-property-decorator';

const noUnit = { maximumFractionDigits: 10, name: { en: 'No Unit', fa: 'بدون واحد' } };

@Component
export class GlobalState extends Vue {
  permissions: Record<string, boolean> = Object.freeze(Object.create(null));
  openDialogs = [];
  currencies = new Map([['--', noUnit]]);
  currencyById = new Map<number, typeof noUnit>();

  hasPermissions(keys: string[]) {
    return keys.every((key) => this.permissions[key]);
  }
}

export const globalState = new GlobalState();

Object.defineProperty(Vue.prototype, '$g', {
  writable: false,
  enumerable: false,
  configurable: false,
  value: globalState,
});
