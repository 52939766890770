import { axiosCompatible as Ajax } from '@/services/http';

export const tradingUnits = {
  namespaced: true,

  state: {
    tradingUnits: [],
    firstTimeLoaded: false,
  },

  getters: {
    allTradingUnits: (state) => state.tradingUnits,
  },

  actions: {
    fetch({ commit }, model) {
      return Ajax.get('/api/v1/common/getAllDetailedTradingUnits', model).then((r) => {
        commit('setTradingUnits', r.data);
      });
    },
    getTradingUnitByID(_store, model) {
      return new Promise((resolve) => {
        Ajax.post('/api/v1/common/getTradingUnit', model).then((r) => {
          resolve(r.data);
        });
      });
    },
  },

  mutations: {
    setFirstTimeLoaded(state) {
      state.firstTimeLoaded = true;
    },
    setTradingUnits(state, data) {
      state.tradingUnits = data;
    },
    addTradingUnit(state, data) {
      state.tradingUnits.push(data);
    },
  },
};
