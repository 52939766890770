import { axiosCompatible as Ajax, httpService } from '@/services/http';

export const businessPartyProfile = {
  namespaced: true,

  state: {
    profile: null,
  },

  getters: {
    user: (state) => state.profile,
  },

  actions: {
    fetch({ commit }, id) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/businessParty/GetBusinessPartyProfile?id=' + id)
          .then((r) => {
            commit('setProfile', r.data);
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    prohibitCustomer({ state, dispatch }, prohibitedTransaction) {
      return new Promise((resolve, reject) => {
        Ajax.put('/api/v1/BusinessParty/UpdateUserProhibitedTransaction', {
          userId: state.profile.user.id,
          prohibitedTransaction,
        })
          .then((r) => {
            dispatch('fetch', state.profile.user.id);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    activateCustomer({ state, dispatch }, isActive) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/BusinessParty/UpdateCustomerIsActiveStatus', {
          id: state.profile.user.id,
          isActive,
        })
          .then((r) => {
            dispatch('fetch', state.profile.user.id);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    activateAgent({ state, dispatch }, isActive) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/BusinessParty/UpdateAgentIsActiveStatus', {
          id: state.profile.user.id,
          isActive,
        })
          .then((r) => {
            dispatch('fetch', state.profile.user.id);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    activateContractor({ state, dispatch }, isActive) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/Contractors/UpdateIsActiveStatus', {
          id: state.profile.user.id,
          isActive,
        })
          .then((r) => {
            dispatch('fetch', state.profile.user.id);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updateEmailConfirmState({ state }, isConfirmed) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/BusinessParty/UpdateUserEmailConfirmState', {
          id: state.profile.user.id,
          isConfirmed,
        })
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updatePhoneNumberConfirmState(_store, model) {
      return Ajax.post('/api/v1/users/' + model.phone_item.id + '/phoneConfirm', { userType: model.type }).then(
        (r) => r.data,
      );
    },
    deleteAgent({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/BusinessParty/DeleteAgent', { id: state.profile.user.id })
          .then((r) => {
            dispatch('fetch', state.profile.user.id);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deleteCustomer({ state }) {
      return httpService.post('BusinessParty/DeleteCustomer', { id: state.profile.user.id }, { showErr: 'dialog' });
    },
  },

  mutations: {
    setProfile(state, data) {
      state.profile = data;
    },
  },
};
