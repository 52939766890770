import { cloneDeep } from 'lodash';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { appModule } from './app.module';
import { businessPartyProfile } from './businessParty/profile.module';
import { invoiceDetailsDialog } from './financial/invoiceDetailsDialog.module';
import { paymentDialog } from './financial/paymentDialog.module';
import { paymentOrderDialog } from './financial/paymentOrderDialog.module';
import { paymentRequestDialog } from './financial/paymentRequestDialog.module';
import { tradingUnits } from './financial/tradingUnits.module';
import { userContacts } from './profile/contacts.module';
import { userNotes } from './profile/notes.module';
import { profile } from './profile/user-profile.module';
import { user } from './security/user.module';
import { ticket } from './support/ticket.module';

Vue.use(Vuex);

function state() {
  const noUnit = { maximumFractionDigits: 8, name: { en: 'No Unit', fa: 'بدون واحد' } };
  const state = { openDialogs: [], currencies: new Map([['--', noUnit]]) };
  return state as typeof state & { app: any };
}

export const store = new Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app: appModule,

    businessParty: {
      namespaced: true,
      modules: {
        profile: businessPartyProfile,
      },
    },

    financial: {
      namespaced: true,
      modules: {
        tradingUnits,
        invoiceDetailsDialog,
        paymentOrderDialog,
        paymentRequestDialog,
        paymentDialog,
      },
    },

    profile: {
      namespaced: true,
      modules: {
        contacts: userContacts,
        notes: userNotes,
        user: profile,
      },
    },
    security: {
      namespaced: true,
      modules: {
        user,
      },
    },
    support: {
      namespaced: true,
      modules: {
        ticket,
      },
    },
  },

  state,

  getters: {} as { app: any },

  actions: {},

  mutations: {
    pushDialog(state, { name, props }: { name: string; props: any }) {
      state.openDialogs.push({ name, props });
    },
    popDialog(state) {
      return state.openDialogs.pop();
    },
    clearDialogs(state) {
      state.openDialogs = [];
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
  },
});

export const initialState = cloneDeep(store.state);
