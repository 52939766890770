import 'vue-class-component/hooks';
import { Component, Vue } from 'vue-property-decorator';
import Router from 'vue-router';
import { sleep } from '@/lib';
import { bus } from '@/services/bus';
import { userManager } from '@/services/oidc';
import { isAuth } from '@/services/user';
import { store } from '@/store';
import { consoleRoutes } from './console';

Vue.use(Router);
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

export const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'kt-menu__item--active',
  linkActiveClass: 'kt-menu__item--open',
  // linkActiveClass: '',
  routes: [
    {
      path: '/auth',
      name: 'Auth',
      component: () => import(/* webpackChunkName: "page-auth" */ '@/views/auth/signIn.vue'),
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      component: () => import(/* webpackChunkName: "page-403" */ '@/views/pages/Unauthorized.vue'),
    },
    consoleRoutes,
    {
      path: '*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "page-404" */ '@/views/pages/NotFound.vue'),
    },
  ],
});

const mobileBreakPoint = 1024;

function setCssVariables(hide, breakpoint: boolean = false) {
  if (!breakpoint) {
    document.body.style.setProperty('--aside-width', hide ? '0px' : 'calc(240px - var(--scrollbar-width))');
    document.body.style.setProperty('--header-height', hide ? '0px' : '50px');
  } else {
    document.body.style.removeProperty('--aside-width');
    document.body.style.setProperty('--header-height', hide ? '0px' : '10px');
  }
}
const overlay = (() => {
  const el = document.getElementById('router-overlay');
  const spinner = el.getElementsByTagName('div')[0];

  return {
    show(auth: boolean, breakpoint: boolean = false) {
      spinner.style.setProperty('display', auth ? 'block' : 'none');
      el.style.setProperty('display', 'flex');
      setCssVariables(auth, breakpoint);
      window.requestAnimationFrame(() => el.style.setProperty('opacity', '1'));
      return sleep(1000);
    },
    async hide(auth: boolean, breakpoint: boolean = false) {
      setCssVariables(auth, breakpoint);
      el.style.setProperty('opacity', '0');
      await sleep(1000);
      spinner.style.setProperty('display', 'none');
      el.style.setProperty('display', 'none');
    },
  };
})();

window.addEventListener('resize', () => {
  const mobileBreak = window.innerWidth <= mobileBreakPoint;
  store.commit('app/setMobileBreak', mobileBreak);
  setCssVariables(!isAuth(), mobileBreak);
});

router.beforeEach(async (to, from, next) => {
  const toAuth = to.name === 'Auth';
  if (toAuth) return next();
  // if (!isAuth()) return next({ name: 'Auth' });
  if (!isAuth()) return userManager?.signinRedirect({ state: to.path });
  const mobileBreak = window.innerWidth <= mobileBreakPoint;
  store.commit('app/setMobileBreak', mobileBreak);

  if (to.name !== from.name) await overlay.show(toAuth || !from.name || from.name === 'Auth', mobileBreak);

  if (!store.getters['app/userIsAuthenticated']) await store.dispatch('app/loadAppInfoAsync');

  if (to.matched.some((record) => record.meta.permission)) {
    const result = await store.dispatch('app/hasPermission', to.meta.permission);
    if (!result) return next({ path: '/unauthorized' });
  }
  if (to.matched.some((record) => record.meta.permissions)) {
    const result = await store.dispatch('app/hasAnyPermission', to.meta.permissions);
    if (!result) return next({ path: '/unauthorized' });
  }
  next();
});

router.afterEach(async (to, from) => {
  bus.emit('after-route-change', { to, from });
  await Vue.nextTick();
  const mobileBreak = window.innerWidth <= mobileBreakPoint;
  store.commit('app/setMobileBreak', mobileBreak);
  if (to.name !== from.name) overlay.hide(to.name === 'Auth', mobileBreak);
  sessionStorage.setItem('currenturl', window.location.href);
});
