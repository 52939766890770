import { getStoredToken } from '@/services/oidc';
import { store } from '@/store';
import { httpService } from './http';

async function initLogRocket() {
  if (process.env.NODE_ENV !== 'production') return;
  const { id, persianName, englishName } = store.state.app.userInfo;
  const whitelist = new Set([
    'fcd6a986-647c-49ed-9e8b-33cfee989151', // بهمنی
    'c23ce291-9a9d-4aaf-9feb-3c4048f78aa9', // مریم همایونی
    '3127812d-a42c-4dfd-b007-ea92202d73b8', // طناز هراتی
    '45e9140b-626d-4f3a-8b8b-78e2df4c7f24', // سعیده پورحنیفه
  ]);
  if (!whitelist.has(id)) return;

  const { default: LogRocket } = await import(/* webpackChunkName: "logrocket" */ 'logrocket');
  const { default: createPlugin } = await import(/* webpackChunkName: "logrocket" */ 'logrocket-vuex');
  createPlugin(LogRocket)(store);
  LogRocket.init('gcree1/parsian-dashboard');
  LogRocket.identify(id, { name: persianName || englishName });
  // LogRocket.getSessionURL(async (sessionURL) => {
  //   const Sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/vue');
  //   Sentry.configureScope((scope) => {
  //     scope.setExtra('sessionURL', sessionURL);
  //   });
  // });
}

export async function initiateUser() {
  await store.dispatch('app/loadAppInfoAsync').loader('login');
  await initLogRocket().loader('login');
  // const { query } = router.currentRoute;
  // const to = typeof query.retUrl === 'string' ? query.retUrl : { name: 'Dashboard' };
  // router.push(to);
}

export function isAuth() {
  return !!getStoredToken();
}

function fetchUserPhones(userId: string, phoneTypeId: number, isActive=true) {
  return httpService.get(`users/${userId}/userPhones`, { phoneTypeId,isActive });
}

function setDefaultBankingEmail(id: string,userId: string) {
  return httpService.put(`Users/SetDefaultBankingEmail`, { id, userId });
}

function getUserBankingEmailsByEmailValue(emailValue: string) {
  return httpService.get(`Users/GetUserBankingEmailsByEmailValue`, { emailValue });
}
function fetchBankingEmails(userId: string,isUserBankingEmailActive: boolean= true, email?: string, ownerName?:string) {
  return httpService.get(`Users/GetBankingEmails`, { userId,isUserBankingEmailActive,email,ownerName });
}
function fetchUserBankingEmails(userId: string,isUserBankingEmailActive: boolean= true, email?: string, ownerName?:string) {
  return httpService.get(`Users/GetUserBankingEmails`, { userId,isUserBankingEmailActive,email,ownerName });
}
function activeUserBankingEmail(id: number) {
  return httpService.put(`Users/ActiveUserBankingEmail`,  id );
}
function deactiveUserBankingEmail(id: number) {
  return httpService.put(`Users/DeactiveUserBankingEmail`,  id );
}
function activeBankingEmail(id: number) {
  return httpService.put(`Users/ActiveBankingEmail`,  id );
}
function deactiveBankingEmail(id: number) {
  return httpService.put(`Users/DeactiveBankingEmail`,  id );
}
function updateActivateUserPhone(phoneId: string) {
  return httpService.put(`users/${phoneId}/activateUserPhone`, { phoneId });
}
function updateDeactivateUserPhone(phoneId: string) {
  return httpService.put(`users/${phoneId}/deactivateUserPhone`, { phoneId });
}


async function fetchUserAddress(guid: string) {
  const { address } = await httpService.get(`users/UserAddress`, { guid });
  return address;
}

function updateUserAddress(guid: string, address: string) {
  return httpService
    .put(`Users/UpdateUserAddress`, { guid, address }, { showErr: 'dialog' })
    .loader('updateUserAddress');
}
function postCodeVerification(phoneId: number, code: number) {
  return httpService.post(`users/${phoneId}/verifyCode`, { code });
}
function updateContact(params: any) {
  return httpService.put(`users/contact/${params.UserContact.userId}`, params, { showErr: 'dialog' });
}
function deleteUser(id: string) {
  return httpService.delete(`users/${id}`, undefined, { showErr: 'dialog' });
}
export const userService = {
  fetchUserPhones,
  getUserBankingEmailsByEmailValue,
  fetchBankingEmails,
  fetchUserBankingEmails,
  activeBankingEmail,
  deactiveBankingEmail,
  activeUserBankingEmail,
  deactiveUserBankingEmail,
  setDefaultBankingEmail,
  updateActivateUserPhone,
  updateDeactivateUserPhone,
  fetchUserAddress,
  updateUserAddress,
  postCodeVerification,
  updateContact,
  deleteUser,
};
