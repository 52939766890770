import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const langKey = 'locale';
const storage = sessionStorage;

if (storage.getItem(langKey) === 'null') storage.removeItem(langKey);

export const i18n = new VueI18n({
  locale: storage.getItem(langKey) ?? 'fa',
  fallbackLocale: 'fa',
});

export const locales = [
  { code: 'fa', name: 'فارسی', country: 'IR', locale: 'fa-IR', useGregoryAsDefault: false },
  { code: 'en', name: 'English', country: 'US', locale: 'en-US', useGregoryAsDefault: true },
];

const rtlLangs = new Set('ae,ar,arc,bcc,bqi,ckb,dv,fa,glk,he,ku,mzn,nqo,pnb,ps,sd,ug,ur,yi'.split(','));

function extractLang(locale: string) {
  return locale.split(/\b/)[0].toLowerCase();
}

export function isRtl() {
  return rtlLangs.has(extractLang(i18n.locale));
}

export async function setLocale(locale = 'fa') {
  if (locale + '' === 'null') locale = 'fa';
  const lang = extractLang(locale);
  await loadLanguageAsync(lang);
  i18n.locale = lang;
  const dir = rtlLangs.has(lang) ? 'rtl' : 'ltr';
  document.documentElement.setAttribute('dir', dir);
  document.documentElement.setAttribute('lang', locale);
  storage.setItem(langKey, locale);
  return locale;
}

const loadedLanguages: string[] = [];

export async function loadLanguageAsync(lang: string) {
  if (loadedLanguages.includes(lang)) return;
  const { messages } = await import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`);
  loadedLanguages.push(lang);
  i18n.setLocaleMessage(lang, messages);
}

export function getCurrentLocale() {
  return locales.find(({ code }) => code === i18n.locale);
}
