import { PaymentStatus, TransactionStatus } from '~types/enums/api';
import { axiosCompatible as ajax, httpService } from '@/services/http';

export const paymentOrderDialog = {
  namespaced: true,

  state: {
    payment: null,
    isLoading: false,
    lockData: null,
  },

  getters: {
    isLoaded: (state) => state.payment != null,
    isLoading: (state) => state.isLoading,
    lockData: (state) => state.lockData,
    invoiceDetails: (state) => (state.payment ? state.payment.invoiceDetails : {}),
    paymentOrder: (state) => (state.payment ? state.payment.paymentOrder : {}),
    payments: (state) => (state.payment ? state.payment.payments : []),
    childPaymentOrders: (state) => (state.payment ? state.payment.childPaymentOrders : []),
    auditLogs: (state) => (state.payment ? state.payment.auditLogs : null),
    depositToCustomerBankAccount: (state) => (state.payment ? state.payment.depositToCustomerBankAccount : null),
    payToCustomerContact: (state) => (state.payment ? state.payment.payToCustomerContact : null),
    readOnly: (state) => (state.payment ? state.payment.readOnly : true),
    paymentsSum: (_state, getters) =>
      getters.payments
        .filter((x) => x.status <= PaymentStatus.Paid || x.status == PaymentStatus.Current)
        .reduce(
          (a, b) => +parseFloat(a) + +parseFloat(b.amount.toFixed(getters.paymentOrder.tradingUnit.decimalPlaces)),
          0,
        ),
    childPaymentOrdersSum: (_state, getters) =>
      getters.childPaymentOrders
        .filter((x) => x.status <= TransactionStatus.Finalized)
        .reduce(
          (a, b) => +parseFloat(a) + +parseFloat(b.amount.toFixed(getters.paymentOrder.tradingUnit.decimalPlaces)),
          0,
        ),
  },

  actions: {
    fetch({ commit, getters }, { guid, setLock } = { guid: undefined, setLock: false }) {
      if (!guid) guid = getters.paymentOrder.guid;
      return new Promise((resolve, reject) => {
        httpService
          .get('Sales/GetPaymentOrderDialog', { guid, setLock })
          .then(({ data, extraData }) => {
            commit('setPayment', data);
            if (extraData.lock) commit('setLockData', extraData.lock);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setPaymentOrderStatus({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UpdatePaymentOrderStatus', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setReturnToCustomerAccount({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/InPersonPaymentOrderFullRefund', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createPayment({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/CreatePayment', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deletePayment({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .delete('/api/v1/Sales/DeletePayment/' + model.id)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    activePayment({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/ActivePayment', { id: model.id })
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setUnlockPaymentOrder(_store, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/SetUnlockPaymentOrder', { id: model.id })
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  mutations: {
    setPayment(state, data) {
      state.payment = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLockData(state, data) {
      state.lockData = data;
    },
  },
};
