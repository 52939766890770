import { camelCase, mapKeys } from 'lodash';
import { SelectOption } from '~types/interfaces/helper';
import { i18n } from '@/lang';

export function toQueryString(json: any, prefix = '') {
  if (!json) return '';
  return Object.keys(json)
    .map((key) => {
      function toQueryParam(value) {
        if (value == null) return;

        // array
        if (Array.isArray(value)) {
          if (!value.length) return;
          return value.map((item) => toQueryParam(item)).join('&');
        }

        // object
        if (typeof value === 'object') return this.toQueryString(value, prefix);

        // other
        return (prefix ? prefix + '.' : '') + encodeURIComponent(key) + '=' + encodeURIComponent(value);
      }
      return toQueryParam(json[key]);
    })
    .filter((param) => !!param)
    .join('&');
}

export function sleep(milliseconds = 1000) {
  return new Promise<void>((resolve) => setTimeout(resolve, milliseconds));
}

export function imageToDataURL(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function tanslationToOptions(path = ''): SelectOption[] {
  return Object.entries(i18n.t(path)).map(([id, name]) => ({ id, name }));
}

export function enumToOptions<T>(obj: T, i18nPath = '', hasAllOption = false, hasStringValue = false): SelectOption<T[keyof T]>[] {
  i18nPath = i18nPath ? i18nPath.replace(/\.?$/, '.') : '';
  
  const options = Object.entries(obj)
    .filter(([, v]) => hasStringValue ? true : typeof v !== 'string')
    .map(([k, v]) => ({ id: v, name: i18n.t(i18nPath + k) }));
  if (hasAllOption) options.unshift({ id: null, name: i18n.t('All') });
  
  return options;
}

export function GUIDenumToOptions<T>(obj: T, i18nPath = '', hasAllOption = false): SelectOption<T[keyof T]>[] {
  i18nPath = i18nPath ? i18nPath.replace(/\.?$/, '.') : '';
  const options = Object.entries(obj).map(([k, v]) => ({ id: v, name: i18n.t(i18nPath + k) }));
  if (hasAllOption) options.unshift({ id: null, name: i18n.t('All') });
  return options;
}

export function camelizeKeys(obj: any) {
  return mapKeys(obj, (_, k) => camelCase(k));
}

// TODO: actually fix
export function uglyBusinessPartyFix(data: any[]) {
  return data.map((row) => {
    row.customer = row.customer ?? row.agent ?? row.contractor ?? {};
    return row;
  });
}

export function isJSON(str) {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}

export function isNumber(value: any) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function moneyFormat(num:number) {
  return isNumber(num) ? new Intl.NumberFormat().format(num) : ''
}
