import { PaymentRequestStatus } from '~types/enums/api';
import { axiosCompatible as ajax, httpService } from '@/services/http';

export const paymentRequestDialog = {
  namespaced: true,

  state: {
    payment: null,
    isLoading: false,
    lockData: null,
  },

  getters: {
    isLoaded: (state) => state.payment != null,
    isLoading: (state) => state.isLoading,
    lockData: (state) => state.lockData,
    paymentRequest: (state) => (state.payment ? state.payment.paymentRequest : {}),
    returnReasons: (state) => (state.payment ? state.payment.returnReasons : {}),
    paymentOrders: (state) => (state.payment ? state.payment.paymentOrders : []),
    auditLogs: (state) => (state.payment ? state.payment.auditLogs : null),
    depositToCustomerBankAccount: (state) => (state.payment ? state.payment.depositToCustomerBankAccount : null),
    payToCustomerContact: (state) => (state.payment ? state.payment.payToCustomerContact : null),
    readOnly: (state) => (state.payment ? state.payment.readOnly : true),
    paymentOrdersSum: (_state, getters) =>
      getters.paymentOrders
        .filter((x) => x.status <= PaymentRequestStatus.Finalized && !x.isReturned)
        .reduce((a, b) => +a + +b.amount.toFixed(getters.paymentRequest.tradingUnit.decimalPlaces), 0),
  },

  actions: {
    fetch({ commit, getters }, { guid, setLock } = { guid: undefined, setLock: false }) {
      if (!guid) guid = getters.paymentRequest.guid;
      return new Promise((resolve, reject) => {
        httpService
          .get('Sales/GetPaymentRequestDialog', { guid, setLock })
          .then(({ data, extraData }) => {
            commit('setPaymentRequest', data);
            if (extraData.lock) commit('setLockData', extraData.lock);
            resolve(data);
          })
          .catch((e) => {
            commit('setPaymentRequest', null);
            reject(e);
          });
      });
    },
    setPaymentRequestStatus({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UpdatePaymentRequestStatus', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setPaymentOrderStatus({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/UpdatePaymentOrderStatus', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createPaymentOrder({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/CreatePaymentOrder', model)
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setUnlockPaymentRequest(_store, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/SetUnlockPaymentRequest', { id: model.id })
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deletePaymentOrder({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/api/v1/Sales/DeletePaymentOrder', { id: model.id })
          .then((r) => {
            dispatch('fetch');
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  mutations: {
    setPaymentRequest(state, data) {
      state.payment = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLockData(state, data) {
      state.lockData = data;
    },
  },
};
